/* .slider-container{
  align-items: center;
  justify-content: center;
} */

@keyframes scroll {
  0% {
    transform: translateX(calc(250px * 10));
  }
  100% {
    transform: translateX(0);
  }
}

.slider-container {
  position: relative;
  top: 12rem;
}
.slider {
  height: 470px;
  /* margin: auto; */
  overflow: hidden;
  position: relative;
  /* width: auto; */
}

.slider .slide-track {
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 20);
}

.slider .slide {
  height: 100px;
  width: 250px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  height: 470px;
  padding-top: 2rem;
}

.skills-item i {
  font-size: 6rem;
  color: #fff;
}

.skills-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}
.skills-item h3 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

/* hovers logos */
.skills-item .bxl-php:hover {
  transform: scale(1.1);
  color: #777bb3;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-typescript:hover {
  transform: scale(1.1);
  fill: #2d79c7 !important;
  transition: 0.3s ease-in-out;
}
.skills-item .bxs-data:hover {
  transform: scale(1.1);
  color: #00799c;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-html5:hover {
  transform: scale(1.1);
  color: #f75421;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-css3:hover {
  transform: scale(1.1);
  color: #2196f3;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-javascript:hover {
  transform: scale(1.1);
  color: #f0db4f;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-react:hover {
  transform: scale(1.1);
  color: #61dbfb;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-redux:hover {
  transform: scale(1.1);
  color: #764abc;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-jquery:hover {
  transform: scale(1.1);
  color: #0868ab;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-sass:hover {
  transform: scale(1.1);
  color: #cd669a;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-bootstrap:hover {
  transform: scale(1.1);
  color: #7309f5;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-tailwind-css:hover {
  transform: scale(1.1);
  color: #38bdf8;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-git:hover {
  transform: scale(1.1);
  color: #de4c36;
  transition: 0.3s ease-in-out;
}
.skills-item .bxl-github:hover {
  transform: scale(1.1);
  color: #000000;
  transition: 0.3s ease-in-out;
  text-shadow: gray 0px 0px 2px;
}
.skills-item .bxl-wordpress:hover {
  transform: scale(1.1);
  color: #1b769c;
  transition: 0.3s ease-in-out;
}
