.me{
  height: 500px;
}

.about-content{
  display: flex;
  flex-direction: column;
  align-items:center;
  
  height: 470px;
  
  color: var(--beige);
  font-family: var(--font-text);
  font-size: 1.1rem;
line-height: 1.5rem;
padding-top: 2rem;

}

.about-content:first-letter {
  font-size: 300%;
  font-weight: bold;
}


.about-photo{
  width: 180px;
  opacity: 0.7;
}

@media (max-width: 576px){
  .about-content{
    text-align: center;

  } 
}