.mt-1 {
  margin-top: 1rem;
}

.main {
  height: 100vh;
  width: 100%;
  /* background-color: var(--dark); */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 40px;
  overflow: hidden;
  position: relative;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url(../../assets/menu.png);
}
.header-smart {
  display: none;
}

.navbar-dark .navbar-toggler {
  border-color: var(--beige) !important;
}

.bg-dark {
  /* background-color: var(--dark) !important; */
  background-color: #011a2cfb !important;
}

.left-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 70%;
}

/* .main-header{
  min-width: 25%;
} */

.photo {
  width: 450px;
  margin-top: 20px;
}
.text-container {
  width: 630px;
}

.text-main {
  font-size: 4rem;
  color: var(--beige);
  display: block;
  font-family: var(--font-secondary);
  white-space: nowrap;
  border-right: 4px solid;
  width: 100%;
  animation: typing 3s steps(31), blink 0.5s infinite step-end alternate;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.text-name {
  color: var(--light-brown);
}

.menu {
  display: none;
}

.section-title {
  margin-top: -3rem;
  color: var(--light-brown);
  font-family: var(--font-secondary);
}

/* media query smartphone */
@media (max-width: 576px) {
  /* p{
    text-align: start;
  } */

  .bg-dark {
    background-color: #011a2cec !important;
  }
  .navbar-toggler {
    font-size: 3rem;
    padding: 0;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    opacity: 1;
    object-fit: cover;
  }
  .main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 130vh; */
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .left-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 6%;
  }
  .logo {
    width: 104px;
    z-index: 10000;
  }

  .text-container {
    width: unset;
  }
  .text-main {
    font-size: 1.8rem;
    width: 100%;
  }
  .section-title {
    margin-top: 0;
  }
  .main-header {
    display: none;
  }
  /* .left-main{

    justify-content: flex-start;
  } */
  .photo {
    width: 400px;
  }
  .menu-img {
    width: 120px;
  }
  .focus-in-contract-bck {
    animation: none;
  }
}

@media (min-width: 970px) {
  .left-main {
    width: 78%;
  }
}

/* media query tablets */
@media (max-width: 991.98px) {
  .main {
    flex-direction: column;
  }
  .main-header {
    display: none;
  }
  .menu {
    display: flex;
    color: var(--beige);
    font-size: 3rem;
  }
  .header-smart {
    display: inherit;
  }
  .navbar-dark .navbar-toggler {
    padding: 0 30%;
    margin: auto;
  }
}

/* effects */

.text-shadow-drop-br {
  -webkit-animation: text-shadow-drop-br 0.6s both;
  animation: text-shadow-drop-br 0.6s both;
}
/* @animation text-shadow-drop-br */
@-webkit-keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}
/* .color-change-2x {
	-webkit-animation: color-change-2x 5s ease-in-out infinite alternate both;
	        animation: color-change-2x 5s ease-in-out infinite alternate both;
} */
/* animation color-change-2x */
@-webkit-keyframes color-change-2x {
  0% {
    background: var(--light-brown);
  }
  100% {
    background: var(--dark);
  }
}
@keyframes color-change-2x {
  0% {
    background: var(--dark);
  }
  100% {
    background: var(--brown);
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/* ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.bounce-in-bck {
  animation: bounce-in-bck 2s both;
}
@keyframes bounce-in-bck {
  0% {
    transform: scale(7);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: scale(1);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: scale(1.5);
    animation-timing-function: ease-in;
  }
  72% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  81% {
    transform: scale(1.24);
    animation-timing-function: ease-in;
  }
  89% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  95% {
    transform: scale(1.04);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

.slide-in-blurred-bl {
  animation: slide-in-blurred-bl 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

/* ----------------------------------------------
  animation slide-in-blurred-bl */
@keyframes slide-in-blurred-bl {
  0% {
    transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
    transform-origin: 100% 100%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) skew(0deg, 0deg);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
