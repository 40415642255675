.content__item {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  counter-increment: itemcounter;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  pointer-events: none;
}
/* .content__item::before {
	color: var(--color-number);
	position: absolute;
	top: 0;
	left: 0;
	content: counters(itemcounter, ".", decimal-leading-zero);
} */

.button {
  pointer-events: auto;
  cursor: pointer;
  background: #e7e7e7;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button--mimas {
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-size: 0.85rem;
	border-radius: 1rem;
	overflow: hidden;
	color: #fff;
	background: #e7e7e7;
}

.button--mimas span {
	position: relative;
	mix-blend-mode: difference;
}

.button--mimas::before {
	content: '';
	background: var(--light-brown);
	width: 120%;
	left: -10%;
	transform: skew(30deg);
	transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.button--mimas:hover::before {
	transform: translate3d(100%,0,0);
}
