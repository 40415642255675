/* global variables */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}

a:-webkit-any-link {
  color: unset;
}

/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Koulen&family=Odibee+Sans&family=Roboto:ital,wght@1,300&display=swap");

/* colors and fonts variables  */
body {
  --dark: #011a2c;
  --brown: #352423;
  --beige: #f6f7ee;
  --light-brown: #c89b48;
  --font-primary: "Koulen", cursive;
  --font-secondary: "Odibee Sans", cursive;
  --font-text: "Roboto", sans-serif;
  background-color: initial;
}

::selection {
  background: var(--beige);
  color: var(--dark);
}

.app {
  position: relative;
  box-shadow: inset -470px 0px 1000px 20px var(--dark);
}

@media (max-width: 576px) {
  .app {
    /* box-shadow: inset -93px -297px 1008px 100px var(--dark); */
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  opacity: 0.3;
  object-fit: cover;
}
.capa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
  mix-blend-mode: difference;
}

@media screen and (min-width: 992px) {
  .capa {
    mix-blend-mode: multiply;
  }
}

.project-button {
  width: 70px;
  height: 35px;
  margin: 0px 20px;
  font-family: var(--font-secondary);
  font-size: 1.1rem;
  color: var(--dark);
  background-color: var(--light-brown);
  border: 2px solid var(--dark);
  border-radius: 5px;
  cursor: pointer;
}

.project-button:hover {
  border: 2px solid var(--beige);
  background-color: var(--light-brown);
  color: var(--beige);
  transition: all 0.2s;
}
