.logo {
  width: 130px;
  position: absolute;
  top: 0px;
  left: 5px;
  z-index: 100;
}
@media (max-width: 576px) {
  .logo {
    width: 75px;
    position: absolute;
    top: 0px;
    left: 5px;
  }
}

.wobble-ver-left:hover {
  animation: wobble-ver-left 1.8s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-20 16:9:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-left
 * ----------------------------------------
 */
@keyframes wobble-ver-left {
  0%,
  100% {
    transform: translateY(0) rotate(0);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateY(-10px) rotate(-6deg);
  }
  30% {
    transform: translateY(5px) rotate(6deg);
  }
  45% {
    transform: translateY(-5px) rotate(-3.6deg);
  }
  60% {
    transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    transform: translateY(-6px) rotate(-1.2deg);
  }
}
